import React, { Component } from 'react';
import Title from './title';
import styles from '../styles/scss/components/input.module.scss'
import cn from 'classnames';
import InputMask from 'react-input-mask';

export default class Index extends Component {
    render() {
        const { name, title, placeholder, style, className, inputContainerClassName, inputTitleClassName = false, error, textarea, type, phone, date, upc, onChange, onBlur, value, disabled } = this.props;
        return (
            <div className={cn(styles.container, className)} style={style}>
                {title && <Title value={title} className={inputTitleClassName ? inputTitleClassName : styles.title} />}
                <div className={cn(styles.inputContainer, inputContainerClassName, { [styles.error]: error, [styles.textarea]: textarea })}>
                    {textarea &&
                        <textarea
                            name={name}
                            className={styles.input}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    }
                    {phone &&
                        <InputMask {...this.props} className={styles.input} mask="(999) 999-9999" maskChar=" " />
                    }
                    {date &&
                        <InputMask {...this.props} className={styles.input} mask="99/99/9999" maskChar=" " />
                    }
                    {upc &&
                        <InputMask {...this.props} className={styles.input} mask="9-99999-99999-9" maskChar=" " />
                    }
                    {!textarea && !phone && !date && !upc &&
                        <input
                            name={name}
                            className={styles.input}
                            placeholder={placeholder}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            type={type}
                            disabled={disabled}
                        />
                    }
                </div>
            </div>
        );
    }
}
