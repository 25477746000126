import React, { Component } from 'react';
import Text from './text';
import styles from '../styles/scss/components/checkbox.module.scss';
import cn from 'classnames';

export default class Index extends Component {
    state = {
        stateValue: false,
    };
    handleClick(e) {
        const { onClick } = this.props;
        const { stateValue } = this.state;
        if (e.key !== "Tab" && e.key !== "Shift") {
            this.setState({ stateValue: !stateValue }, () => {
                if (onClick) onClick(this.state.stateValue);
            });
        }
        
    }
    render() {
        const { value, name, text, style, className, useOnlyValue = false  } = this.props;
        const { stateValue } = this.state;
        return (
            <div className={cn(styles.container, className)} style={style} tabIndex={0} role = "button" onKeyDown={this.handleClick.bind(this)} onClick={this.handleClick.bind(this)}>
                <input type='checkbox' name={name} checked={useOnlyValue ? value : value || stateValue} className={styles.hide} readOnly />
                <div className={styles.box}>{(useOnlyValue ? value : value || stateValue) && <i className='fa fa-check' />}</div>
                {text && <Text value={text} className={styles.title} />}
            </div>
        );
    }
}
