import React, { Component } from 'react';
import Title from './title';
import styles from '../styles/scss/components/expandCollapse.module.scss'
import cn from 'classnames';

export default class Index extends Component {
    state = {
        show: false,
    };
    handleClick() {
        const { onClick } = this.props;
        const { show } = this.state;
        if (onClick) onClick();
        this.setState({ show: !show });
    }
    render() {
        const { children, title, className, inverted } = this.props;
        const { show } = this.state;
        return (
            <div className={cn(styles.container, className, { [styles.inverted]: inverted })} role ="none" onKeyDown={this.handleClick.bind(this)} onClick={this.handleClick.bind(this)}>
                <div className={styles.header}>
                    <Title style={{ color: 'white' }} value={title} alignLeft className={styles.title} />
                    <div className={styles.button}>
                        <i className={`fa fa-${!show ? 'plus' : 'minus'}`} />
                    </div>
                </div>
                {show && children && <div className={styles.body}>{children}</div>}
            </div>
        );
    }
}
