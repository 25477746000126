import React, { Component } from 'react';
import styles from '../styles/scss/components/select.module.scss';
import cn from 'classnames';
import Title from './title';
import { isObject as _isObject, isEmpty as _isEmpty, find as _find } from "lodash"

export default class Index extends Component {
    state = {
        show: false,
        selected: {},
    }

    constructor(props) {
        super(props);
        this.nodeRef = React.createRef();
    }

    onClickSelect = (e) => {
        const { show } = this.state;
        if (e.key !== "Tab" && e.key !== "Shift") {
            this.setState({ show: !show });
        }
    }

    onClickOption = item => {
        const { selected } = this.state;
        const { onChange } = this.props;
        if (onChange && selected.id !== item.id) onChange(item);
        this.setState({ selected: item });
    }

    handleClick(e) {
        if(this.nodeRef || this.nodeRef.current == null){
            return
        }
        if (!this.nodeRef.current.contains(e.target)) this.setState({ show: false });
    }

    componentDidMount() {        
        if(this.props.selected){
            this.setState({
                selected: this.props.selected === null ? {} : _find(this.props.values, {'value': this.props.selected})
            })
        }

        document.addEventListener('mousedown', this.handleClick.bind(this), false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick.bind(this), false);
    }

    render() {
        const { placeholder, values, className, noMargin, newSelect = false, title, titleClassName } = this.props;
        const { show, selected } = this.state;
        return (
            <>
                {title && <Title value={title} className={titleClassName} />}
                <div
                    tabIndex={0}
                    role='button'
                    ref={this.nodeRef}
                    className={cn(newSelect ? styles.newContainer : styles.container, className, { [styles.noMargin]: noMargin })}
                    onClick={this.onClickSelect.bind(this)}
                    onKeyDown={this.onClickSelect.bind(this)}
                    >
                    {
                        _isEmpty(selected) ?
                        <p>{placeholder || 'Select value'}</p> : <></>
                    }
                    {
                        !_isEmpty(selected) && selected.value !== undefined ?
                        <p>{selected.text}</p> : <></>
                    }
                    {
                        !_isEmpty(selected) && selected.value === undefined ?
                        <p>{selected.v}</p> : <></>
                    }
                    <i className={`fa fa-angle-${show ? 'up' : 'down'}`} />
                    {show && (
                        <div className={newSelect ? styles.newOptions : styles.options}>
                            {values ? (
                                values.map((v, i) => (
                                    _isObject(v) ?
                                    <div
                                        tabIndex={0}
                                        role='button'
                                        onKeyDown={this.onClickOption.bind(this, { id: i, ...v })}
                                        key={i}
                                        onClick={this.onClickOption.bind(this, { id: i, ...v })}
                                        className={selected.id === i ? styles.active : styles.option}>
                                        {v.text}
                                    </div>
                                    :
                                    <div
                                        key={i}
                                        tabIndex={0}
                                        role='button'
                                        onKeyDown={this.onClickOption.bind(this, { id: i, v })}
                                        onClick={this.onClickOption.bind(this, { id: i, v })}
                                        className={selected.id === i ? styles.active : styles.option}>
                                        {v}
                                    </div>
                                ))
                            ) : (
                                <p>No Values</p>
                            )}
                        </div>
                    )}
                </div>
            </>
        );
    }
}
