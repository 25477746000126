import axios from "axios"
import { isBrowser } from "./util"

export const sendContact = (values, requestType = '') => {
    const isSampleRequest = requestType === 'sample-request'
    const isTechnicalSpecialist = requestType === 'technical-specialist'
    return new Promise(function(resolve, reject) {
        if(!isBrowser()){
            reject("Do not do this from Node!")
        }else{
            const contactSubmitUrl = isSampleRequest ? 'https://xrii6wp2wc62shqqbzc4t2hu4y0krrzj.lambda-url.us-west-2.on.aws/' : isTechnicalSpecialist ? 'https://zqbo6stus5ixlorv52l6orcblq0xniei.lambda-url.us-west-2.on.aws/' : process.env.GATSBY_CONTACT_API_URL
            values.env = process.env.GATSBY_DATOCMS_ENV;
            axios.post(
                `${contactSubmitUrl}`,
                values,
                {
                    headers: {
                        'X-Api-Key': 'siW3Dbkdv07NjsemeBypH1qC246iFTJ68iqBX6WG',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            ).then((result) => {
                resolve(result)
            }).catch((err)=>{
                console.log('error ',err)
                reject(err)
            })
        }
    })
}